import { Link, useLocation, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Descriptions,
  Input,
} from "antd";
import {
  EditOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import type { InputRef } from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useRef, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { timeConverter } from "../../../utils/timeConverter";

const { Header } = Layout;
const { Title } = Typography;

interface Record {
  nickName: string;
  recordId: string;
  moving_distance: number;
  co2_reduction: number;
  planted_tree: number;
  moving_time: number;
  image: string[];
  moving_type: string;
  createdAt: string;
}

export const DetailPage = () => {
  const location = useLocation();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record>(location.state);
  const inputRef = useRef<InputRef>(null);

  const [api, contextHolder] = useNotification();

  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleUpdateRecord = async () => {
    if (
      inputRef.current?.input?.value === null ||
      inputRef.current?.input?.value === undefined ||
      inputRef.current?.input?.value === ""
    ) {
      // Enter 입력이 되면 클릭 이벤트 실행
      api.error({
        message: "올바른 값을 입력하세요",
      });
      return;
    }
    setLoading(true);

    await LPPAXIOS.put(`/admin/record`, {
      recordId: id,
      moving_distance: Number(inputRef.current?.input?.value),
      type: data.moving_type,
    })
      .then((res) => {
        api.success({
          message: "update success",
        });

        console.log(res.data.data);
        setData((prev) => {
          return {
            ...prev,
            moving_distance: Number(inputRef.current?.input?.value),
            co2_reduction: res.data.data.co2_reduction,
            planted_tree: res.data.data.planted_tree,
          };
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 400) {
            api.error({
              message: "요청이 잘못되었습니다.",
            });
          } else if (error.response.status === 404) {
            api.error({
              message: "해당 기록을 찾을 수 없습니다.",
            });
          } else {
            api.error({
              message: "해당 요청을 수행할 수 없습니다.",
            });
          }
        } else if (error.request) {
          api.error({
            message: "요청이 잘못되었습니다.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
        setEditMode(false);
      });
  };

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };
  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (
        e.currentTarget.value === null ||
        e.currentTarget.value === undefined ||
        e.currentTarget.value === ""
      ) {
        // Enter 입력이 되면 클릭 이벤트 실행
        api.error({
          message: "올바른 값을 입력하세요",
        });
      } else {
        handleUpdateRecord();
      }
    }
  };

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>기록</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Record.Main.url()}>기록</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Descriptions>
          <Descriptions.Item label="닉네임">{data.nickName}</Descriptions.Item>
          <Descriptions.Item label="데이터 기록시간">
            {timeConverter(data.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="이동수단">
            {data.moving_type === "bicycle"
              ? "자전거"
              : data.moving_type === "bus"
              ? "버스"
              : "걷기"}
          </Descriptions.Item>
          <Descriptions.Item label="이동거리">
            <span>
              {editMode === true ? (
                <span>
                  <Input
                    ref={inputRef}
                    maxLength={21}
                    step="0.001"
                    //onInput={onInputOnlyNumber}
                    onInput={(e) =>
                      (e.currentTarget.value = e.currentTarget.value.replace(
                        /[^-\.0-9]/g,
                        ""
                      ))
                    }
                    onKeyPress={handleOnKeyPress}
                    defaultValue={data.moving_distance}
                    size="small"
                  />
                  <CheckSquareTwoTone
                    style={{ fontSize: 16 }}
                    twoToneColor="#52c41a"
                    onClick={handleUpdateRecord}
                  />
                </span>
              ) : (
                <span>{data.moving_distance}km</span>
              )}
              {editMode === true ? (
                <CloseSquareTwoTone
                  style={{ fontSize: 16 }}
                  twoToneColor="#eb2f96"
                  onClick={handleEditMode}
                />
              ) : (
                <EditOutlined
                  style={{ fontSize: 16 }}
                  onClick={handleEditMode}
                />
              )}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="이동시간">
            {Math.floor(data.moving_time / 60)
              ? `${Math.floor(data.moving_time / 60)}분 `
              : " "}
            {data.moving_time % 60}초
          </Descriptions.Item>
          <Descriptions.Item label="탄소감축량">
            {data.co2_reduction}kg/co2
          </Descriptions.Item>

          <Descriptions.Item label="심은소나무">
            {data.planted_tree}그루
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        <Image
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data.image}`}
          alt="product_thumbnail"
          className=" max-w-[300px]"
        />
      </HContent>
      {contextHolder}
    </>
  );
};
